import axios from './lib/axios'
const prefix = '/oauth'

export default {
  // 登录
  login(params) {
    return axios.post(`${prefix}/login/SCMS`, params)
  },
  // 获取验证码
  sendVerifyCode(params) {
    return axios.get(`${prefix}/sendVerifyCode`, { params })
  },
  // 修改密码
  resetPassword(params) {
    return axios.post(`${prefix}/resetPassword`, params)
  },
  getPublicKey(){
    return axios.get(`${prefix}/getPublicKey`)
  },
  // 登录验证码
  identifyImage(params) {
    return axios.get(`/oauth/identifyImage`, { params })
  },

}